<template>
  <div class="Client">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0">
        <banner 
          title="SERVICES"
          :breadcrumb="[
            { label: 'Package Services',  },
            { label: 'Services List' },
          ]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row" style="margin-top: -65px">
          <div class="col-xl-12 col-lg-12 col-md-12 -col-12">
            <div
              class="card"
              style="border-radius: 10px;box-shadow: #80808000 0px 0px 0px 0px;background: transparent;"
            >
              <div class="card-body">
                <div class="row pt-1">
                  <div
                    class="col-md-2 col-xl-2 col-6"
                    style="padding: 5px 5px 5px 5px; line-height: 1"
                    v-for="(cat, index) in categories"
                    :key="index"
                  >
                    <div
                      class="card "
                      style="height: 100%; margin-top: -14px"
                      @click="getSedrvices(cat.service_category_relation, cat.name)"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <div
                        class="card-body text-center"
                        style="
                          line-height: 1;
                          padding: 12px 5px 0px 0px;
                          box-shadow: #80808000 0px 0px 0px 0px
                          transition: background 0.3s, border 0.3s, border-radius 0.3s,
                            box-shadow 0.3s;

                          border-radius: 10px;
                        "
                      >
                        <div>
                          <img
                            style="width: 35px; height: 35px"
                            :src="categoryServiceIconfind(cat)"
                          />
                        </div>
                        <div>
                          <strong style="color: #00364f">
                            {{ cat.name }}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header" style="background-color: #00364f">
            <h4 class="modal-title text-white" id="exampleModalLabel">
              <strong>Service</strong>
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body pt-2">
            <div class="row border-row">
              <div
                class="col-md-3 col-xl-3 col-6"
                style="padding: 5px 5px 5px 5px; line-height: 1"
                v-for="(service, index) in services"
                :key="index"
              >
                <div class="card border" style="height: 100%; margin-top: -19px">
                  <div
                    class="card-body text-center"
                    @click="redirect(service.service)"
                    style="
                      line-height: 1;
                      padding: 12px 5px 0px 0px;
                      box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                      transition: background 0.3s, border 0.3s, border-radius 0.3s,
                        box-shadow 0.3s;
                      border-radius: 10px 10px 10px 10px;
                    "
                  >
                    <div class="row">
                      <div class="col text-start">
                        <span
                          v-if="service.service.package_service"
                          style="color: #00364f"
                        >
                          <span v-if="service.service.package_service.package">
                            <small
                              v-if="
                                service.service.package_service.package.package == 'Basic'
                              "
                              class="text-center d-none d-xl-block d-lg-block d-md-block"
                              style="
                                background-color: #00364f;
                                color: white;
                                width: 65%;
                                border-radius: 15px;
                                padding: 3px 7px 3px 7px;
                                margin-top: -8px;
                                box-shadow: 0px 0px 5px gray;
                              "
                              >{{ service.service.package_service.package.package }}
                            </small>

                            <small
                              v-if="
                                service.service.package_service.package.package == 'Pro'
                              "
                              class="text-center d-none d-xl-block d-lg-block d-md-block"
                              style="
                                background-color: #f21000;
                                color: white;
                                width: 45%;
                                border-radius: 15px;
                                padding: 3px 7px 3px 7px;
                                margin-top: -8px;
                                box-shadow: 0px 0px 5px gray;
                              "
                              >{{ service.service.package_service.package.package }}
                            </small>
                            <small
                              v-if="
                                service.service.package_service.package.package ==
                                'Advance'
                              "
                              class="text-center d-none d-xl-block d-lg-block d-md-block bg-primary"
                              style="
                                color: white;
                                width: 85%;
                                border-radius: 15px;
                                padding: 3px 7px 3px 7px;
                                margin-top: -8px;
                                box-shadow: 0px 0px 5px gray;
                              "
                              >{{ service.service.package_service.package.package }}
                            </small>

                            <small
                              v-if="
                                service.service.package_service.package.package == 'Basic'
                              "
                              class="d-block d-xl-none d-lg-none d-md-none text-center"
                              style="
                                background-color: #00364f;
                                width: 60%;
                                color: white;
                                border-radius: 15px;
                                padding: 3px 7px 3px 7px;
                                font-size: 8px;
                                box-shadow: 0px 0px 5px gray;
                                margin-top: -8px;
                                margin-left: -4px;
                              "
                              >{{ service.service.package_service.package.package }}
                            </small>

                            <small
                              v-if="
                                service.service.package_service.package.package == 'Pro'
                              "
                              class="d-block d-xl-none d-lg-none d-md-none text-center"
                              style="
                                background-color: #f21000;
                                width: 45%;
                                color: white;
                                border-radius: 15px;
                                padding: 3px 7px 3px 7px;
                                font-size: 8px;
                                box-shadow: 0px 0px 5px gray;
                                margin-top: -8px;
                                margin-left: -4px;
                              "
                              >{{ service.service.package_service.package.package }}
                            </small>
                            <small
                              v-if="
                                service.service.package_service.package.package ==
                                'Advance'
                              "
                              class="d-block d-xl-none d-lg-none d-md-none text-center bg-primary"
                              style="
                                width: 80%;
                                color: white;
                                border-radius: 15px;
                                padding: 3px 7px 3px 7px;
                                font-size: 8px;
                                box-shadow: 0px 0px 5px gray;
                                margin-top: -8px;
                                margin-left: -4px;
                              "
                              >{{ service.service.package_service.package.package }}
                            </small>
                          </span></span
                        >
                      </div>

                      <div class="col">
                        <span v-if="service.service.status == 'false'">
                          <!-- class="d-none d-xl-block d-lg-block d-md-block" -->
                          <span
                            class="d-none d-xl-block d-lg-block d-md-block"
                            style="
                              width: 10px;
                              height: 10px;
                              background-color: #f21000;
                              border-radius: 100%;
                              position: absolute;
                              right: 5px;
                              top: 5px;
                            "
                          ></span>
                          <span
                            class="d-block d-xl-none d-lg-none d-md-none"
                            style="
                              width: 10px;
                              height: 10px;
                              background-color: #f21000;
                              border-radius: 100%;
                              position: absolute;
                              right: 5px;
                              top: 5px;
                            "
                          ></span>
                        </span>
                        <span v-else>
                          <span
                            class="d-none d-xl-block d-lg-block d-md-block"
                            style="
                              width: 10px;
                              height: 10px;
                              background-color: green;
                              border-radius: 100%;
                              position: absolute;
                              right: 5px;
                              top: 5px;
                            "
                          ></span>
                          <span
                            class="d-block d-xl-none d-lg-none d-md-none"
                            style="
                              width: 10px;
                              height: 10px;
                              background-color: green;
                              border-radius: 100%;
                              position: absolute;
                              right: 5px;
                              top: 5px;
                            "
                          ></span>
                        </span>
                      </div>
                    </div>

                    <div style="margin: 5px">
                      <!-- <img style="width:25px" src="https://apk.mudhrape.com/wp-content/uploads/2022/01/fab2.png" alt=""> -->

                      <span class="d-none d-xl-block d-lg-block d-md-block">
                        <img
                          style="width: 30px"
                          :src="serviceIconfind(service.service)"
                        />
                      </span>
                      <span class="d-block d-xl-none d-lg-none d-md-none">
                        <img
                          style="width: 25px"
                          :src="serviceIconfind(service.service)"
                        />
                      </span>
                    </div>

                    <small>
                      <strong
                        class="d-none d-xl-block d-lg-block d-md-block"
                        v-if="service.service"
                        style="
                          text-transform: uppercase;
                          color: #00364f;
                          font-size: 12.5px;
                        "
                      >
                        {{ service.service.name }}
                      </strong>

                      <strong
                        class="d-block d-xl-none d-lg-none d-md-none"
                        v-if="service.service"
                        style="text-transform: uppercase; color: #00364f; font-size: 9px"
                      >
                        {{ service.service.name }}
                      </strong>
                    </small>

                    <div class="text-end" v-if="service.service">
                      <span
                        v-if="
                          checkRetailerPackage(service.service.package_service) == true
                        "
                      >
                        <i
                          style="position: absolute; right: 5px; bottom: 5px"
                          class="fas fa-lock-open"
                        ></i>
                      </span>
                      <span
                        v-if="
                          checkRetailerPackage(service.service.package_service) == false
                        "
                      >
                        <span style="position: absolute; right: 5px; bottom: 5px"
                          ><i class="fa fa-lock" aria-hidden="true"></i
                        ></span>
                      </span>
                    </div>
                    <div class="text-end" v-else>
                      <span style="position: absolute; right: 5px; bottom: 5px"
                        ><i class="fa fa-lock" aria-hidden="true"></i
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm text-white"
              style="background-color: #f21000"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
        </div>
      </div>
    </div>

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>
</template>

<script>
import Banner from "../../../components/retailer/comman/Banner.vue";
import Spinner from "../../../components/retailer/comman/Spinner.vue";
import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "Packages",
  retailer_id: "",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      Category: "",
      loading: false,
      packages: [],
      retailer_id: "",
      services: [],
      packageservice: [],
      retailerpackage: [],
      form: new Form({}),
      categories: [],
    };
  },
  methods: {
    checkRetailerPackage(pkg_id) {
      // return true;
      // var pkgService = pkg_id;

      console.log(pkg_id);

      console.log(this.packages);
      var pkg = this.packages.find((p) => p.package_id == pkg_id.package_id);

      console.log(pkg);

      if (pkg) {
        return true;
      } else {
        return false;
      }
    },
    getSedrvices(service, cat) {
      // console.log(service);
      this.services = service;
      this.Category = cat;
    },
    serviceIconfind(service) {
      if (service.logo != null) {
        this.serviceIconImg =
          this.$store.state.imgUrl +
          "/service-icons/" +
          service.id +
          "/thumbs/" +
          service.logo;
      } else {
        this.serviceIconImg = this.$store.state.placeholderImg;
      }
      return this.serviceIconImg;
    },
    categoryServiceIconfind(cat) {
      var img;
      if (cat.logo != null) {
        img =
          this.$store.state.imgUrl + "/servicecategory/" + cat.id + "/thumbs/" + cat.logo;
      } else {
        img = this.$store.state.placeholderImg;
      }
      return img;
    },

    redirect(service) {
      console.log("=========================");
      console.log(service);
      console.log("=========fg================");
      var data = false;
      if (service.package_service) {
        data = this.checkRetailerPackage(service.package_service);
      }
      if (data != true) {
        this.$swal.fire({
          position: "top-center",
          icon: "warning",
          title:
            "<small>This service is not assign in your Active Package if you want to take this service then Upgrade your Package</small>",
          confirmButtonColor: "#00364f",
        });
      } else {
        $("#exampleModal").modal("hide");
        // itr
        var srv = {};
        console.log(service);


          if (service.status == "true") {
            srv.service_id = service.id;
            srv.gvtFeeType = service.gvtFeeType;
            srv.serviceName = service.name;
            srv.categoryName = this.Category;
            localStorage.setItem("service", JSON.stringify(srv));
            // this.$router.push({ name: "MyService" });




              if(service.id == 2){
                this.$router.push("/retailer/service-module-partnership-registration");
              }

              if(service.id == 38){
                this.$router.push("/retailer/income-tax-return-filling");
              }


          }



        // if (service.id != 15) {
        //   if (service.status == "true") {
        //     srv.service_id = service.id;
        //     srv.serviceName = service.name;
        //     srv.categoryName = this.Category;
        //     localStorage.setItem("service", JSON.stringify(srv));
        //     this.$router.push({ name: "MyService" });
        //   }
        // } else {
        //   var Iso = {};
        //   Iso.service_id = service.id;
        //   Iso.serviceName = service.name;
        //   Iso.categoryName = this.Category;
        //   localStorage.setItem("proprietorship", JSON.stringify(Iso));
        //   this.$router.push({ name: "ISO" });
        // }

        // if (service.id == 38) {
        //   this.$router.push({ name: "Itr" });
        // }
        // propritership
        // if (service.id == 1) {
        //   var proprietorship = {};
        //   proprietorship.service_id = 1;
        //   localStorage.setItem("proprietorship", JSON.stringify(proprietorship));
        //   this.$router.push({ name: "ProprietorShip" });
        // }
        // partnership
        // if (service.id == 16) {
        //   var PartnerShipServices = {};
        //   PartnerShipServices.service_id = service.id;
        //   localStorage.setItem("proprietorship", JSON.stringify(PartnerShipServices));
        //   this.$router.push({ name: "PartnerShipService" });
        // }
        // iso
        // if (service.id == 15) {
        //   var Iso = {};
        //   Iso.service_id = service.id;
        //   localStorage.setItem("proprietorship", JSON.stringify(Iso));
        //   this.$router.push({ name: "ISO" });
        // }
        // if (service.id == 14) {
        //   Iso = {};
        //   Iso.service_id = service.id;
        //   localStorage.setItem("proprietorship", JSON.stringify(Iso));
        //   this.$router.push({ name: "ISO" });
        // }
        // tradmark
        // if (service.id == 31) {
        //   var Trademark = {};
        //   Trademark.service_id = service.id;
        //   Trademark.serviceName = service.name;
        //   localStorage.setItem("trademark", JSON.stringify(Trademark));
        //   this.$router.push({ name: "Trademark" });
        // }
        // if (service.id == 32) {
        //   Trademark = {};
        //   Trademark.service_id = service.id;
        //   Trademark.serviceName = service.name;
        //   localStorage.setItem("trademark", JSON.stringify(Trademark));
        //   this.$router.push({ name: "Trademark" });
        // }
        // if (service.id == 33) {
        //   Trademark = {};
        //   Trademark.service_id = service.id;
        //   Trademark.serviceName = service.name;
        //   localStorage.setItem("trademark", JSON.stringify(Trademark));
        //   this.$router.push({ name: "Trademark" });
        // }
      }
    },
    loadPackages() {
      var id;
      id = JSON.parse(localStorage.getItem("userData")).id;
      this.$axios
        .get(`retailer/retailerpackage?retailer_id=${id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.packages = res.data.data.data;
        });

      // .finally(() => (this.loading = false));
    },
    loadServicesCategories() {
      this.loading = true
      this.$axios
        .get("retailer/servicecategory?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.categories = res.data.data;
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      // this.loadRetailerPackages();
      this.loadServicesCategories();
      this.loadPackages();
      if (localStorage.getItem("service") != null) {
        localStorage.removeItem("service");
      }
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  background-color: white;
  box-shadow: 2px 2px 8px gray;
  margin-left: 8px;
  border-radius: 15px;
}

.package-card {
  box-shadow: 0px 0px 22px 0px rgb(0 0 0 / 16%);
  margin-left: 8px;
  border-radius: 15px;
  position: relative;
  height: 100%;
  width: 96%;
}

.border-row {
  margin-left: -8px;
  margin-right: -8px;
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
</style>
